.customerLoginCaptchaCodeDiv {
    /* border:1px solid blue;  */
    display: flex;
    align-items: center;
    font-size: 25px;
    font-family: 700;
    justify-content: center;
    background-position: center;
    background-size: cover;
    flex-direction: row;
    background-blend-mode: lighten;
}

@media only screen and (max-width:1200px) {
    .customerLoginCaptchaCodeDiv {
        /* border:1px solid blue;  */
        display: flex;
        align-items: center;
        background-position: center;
        background-size: cover;
        justify-content: center;
        font-size: 25px;
        font-family: 700;
        flex-direction: row;
        background-blend-mode: lighten;
    }
}

@media only screen and (max-width:600px) {
    .customerLoginCaptchaCodeDiv {
        /* border:1px solid blue;  */
        display: flex;
        background-blend-mode: lighten;
        align-items: center;
        font-size: 25px;
        font-family: 700;
        justify-content: center;
        flex-direction: row;
        background-position: center;
        background-size: cover;
    }
}