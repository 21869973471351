
.productSliderMainDiv{
    margin-top: 2%;
    margin-bottom: 1%;
    background-color: #f6f5f5;
}
.productinformation p{
   margin-left: 7px;
   
}

.productSliderImage{
    margin-top: 1%;
    margin-left: 16%;
    width: 70%;
    height: 280px;
}
.productinformation{
    margin-top: 3px;
    margin-left: 16%;
    background-color: aliceblue;
    color: black;
    width: 70%;
    text-align: left;
    
}

@media only screen and (max-width: 600px){
    
    .productSliderImage{
        
        margin-left: 5%;
        width: 90%;
        height: 150px;
    }
    /* margin-left: 16%;
    width: 50%; */
    .productinformation p{
        margin-left: 7px;
        font-size: 60%;   
     }
     .productinformation{
        margin-top: 3px;
    margin-left: 5%;
    background-color: rgb(255, 255, 255);
    color: black;
    width: 90%;
    text-align: left;
     }
}