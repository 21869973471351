.submitButtonChangePassword , .loginButtonChangePassword {
    background-color:#F39C12; 
    padding: 5px 25px;
    color  :white ; 
    border:none;
}
.catchaCodeChangePassword {
    font-size : 15px;
   
}
.reFressCaptchaChangePassword {
    width : 100%;
}
.captchaImageChangePassword{
    
    margin-left: 0%;
    height: 10vh;
    width: 40%;
}

.captchaCodeDiv {
    padding : 10px 0px;
  
     background-blend-mode: lighten;
     background-position: center;
     background-size: cover;
    font-size : 25px ;font-family: 700;
}




@media screen and (max-width: 480px) {

    .loginButtonChangePassword{
        /* margin-top: 30px;    */
    }
    .submitButtonChangePassword{
        background-color:#F39C12; 
    }
    .captchaInputField{
      margin-top :15px;
    }
    .captchaCodeChangePassword{
      margin-top :15px;
      /* border:2px solid blue; */
    }
    .reFressCaptchaChangePassword {
       width : 20px;
    }
   
.captchaCodeDiv {

  
     background-blend-mode: lighten;
     background-position: center;
     /* background-size: cover; */
    font-size : 20px ;font-family: 700;
}

 }
@media screen and (max-width: 1200px) {

    .reFressCaptchaChangePassword {
        width : 20px;
     }
     .captchaInputField{
        margin-top :15px;
      }

 }