.wishlistButton{
    background-color:#54b6f5;
    /* background-color:#ff406c;; */
    padding: 5px;
    border: none;
    margin-top: 1%;
    margin-bottom: 4%;
    float: left;
    color: white;
    padding-inline: 21px;
  
  }