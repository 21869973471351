.returnPolicyMainDiv{
    margin-top: 3%;
    margin-bottom: 1%;
    margin-left: 15%;
    margin-right: 15%;
    background-color: white;
}

.returnPolicTable{

    border: 1px solid black;
}

.returnPolicTable tr td{
    border: 1px solid black;
    text-align: left;
}

.termConditionPara p{
    text-align: left;
}

.CONTINUEButtonOrderConfirm{
    background-color:#F39C12;;
    padding: 5px;
    border: none;
    margin-top: 1%;
    margin-bottom: 4%;
    float: left;
    color: white;
    padding-inline: 9px;
}

@media (max-width: 500px) 
{
    .returnPolicyMainDiv{
        margin-top: 0%;
        margin-left: 2%;
        margin-right: 2%;
    }
}