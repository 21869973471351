.newOrderDetailsLink{
    background-color: #F39C12;
    width: 100%;
    height: 110%;
    padding: 5%;
    outline: none;
    color: white;
    border: none;
    font-size: 90%;
}
.newOrderDetailsButton{
    background-color: #F39C12;
    width: 100%;
    height: 90%;
    padding: 3%;
    padding-top: 6%;
    outline: none;
    color: white;
    border: none;
    font-size: 90%;
}