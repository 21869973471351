
.regFormColor{

  background-color: #F4F6F6;
}

.formDIV{

    margin-left: 20%;
    margin-right: 20%;
    background-color: #f2f2f2;
    height: fit-content;
    box-shadow: 0 1px 14px #f2f2f2;
    margin-bottom: 1%;
    padding-bottom:1%
}
.captcha{
  width: 20vw;
  height: 7vh;
  margin-right: 2%;
  margin-bottom: 2%;
}
/* 
.header{
    background-color: #F4F6F6;
    height: 120px;
    width: 100%;
    
} */


  .contactButtonREG{
    margin-top: 5%;
  }

   .RegistrationPageButtonRegistration{
    background-color: #F39C12;
    padding-top:2%;
    padding-bottom: 2%;
    padding-right: 10%;
    padding-left: 10%;
    margin-top: 1.5%;
    outline: none;
    color: white;
    border: none;
  }

  .RegistrationPageButtonLogin{
    background-color: #F39C12;
    padding-top:3%;
    padding-bottom: 3%;
    padding-right: 10%;
    padding-left: 10%;
    margin-top: 1.5%;
    outline: none;
    color: white;
    border: none;
  }

  .RegistrationPageButtonLogin:hover{
    color: white;
  }

  .backToHome{

    margin-top: 5%;
  }


  .contactButtonREG:focus{

        outline: none;

  }


  .secondLogo{

    height: 140px;
  }

  ::placeholder { 
                  
    /* Firefox, Chrome, Opera */
    text-align: center;
}

.formDataFiled{

    margin-left: 5%;
    margin-right: 5%;
    
}


.h3Data{

    padding-top: 2%;
    padding-bottom: 2%;
    color: orange;
}

.emailImage{

    height: 25px;
}


.termconDiv{

    height: 600px;
    width: 80%;
    background-color: white;

    overflow-y: scroll;

    margin-left: 10%;
}


.captchaImage{

    height: 50%;
    width: 30%;
}

.catchaCode{

    position: absolute;
    bottom: 50%;
    left: 42%;
}

.reFressCaptcha{

    cursor: pointer;
    height: 85%;
    width: 5%;



}

.loginHeaderDivO{
  width: 30%;
  margin-left: 35%;
  margin-top: 3%;
}



.rainbowImage {

  width: 70%;
  height: 50px;
  margin-top: 5px;
  padding-left: 0% ;
  
}

@media only screen and (max-width: 800px){

    .formDIV{

        height:10%;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
    }


    .titleLogo{

        width: 250px;
        height: 100px;
  
      }
  
      .secondLogo{
  
       
        width: 100px;
        height: 100px;
      }

  .contactButtonREG{

    margin-top: 0%;

  }

 

  .secodnDivForm{
    margin-top: 5%;

  }


  .reFressCaptcha{

    height: 40px;
    width: 50px;
    
  }

  .rainbowImage {
    
    width: 100%;
    height: 100%;
    padding-top: 1px;
    padding-left: 0% ;
  }


}


/* Media query for 370 */
@media only screen and (max-width: 500px){

  .userbothSign{
    font-size: 100%;
  }

    .formDIV{

        height: 1400px;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
    }
    .captchaImage{

      height: 54%;
      width: 45%;
  }
  .catchaCode{

    position: absolute;
    
    bottom: 50%;
    left: 37%;
}
    .captcha{
      width: 35vw;
      height: 7vh;
      margin-right: 2%;
      margin-bottom: 2%;
    }
    .titleLogo{

        width: 250px;
        height: 100px;
  
      }
  
      .secondLogo{
  
       
        width: 100px;
        height: 100px;
      }

  .contactButtonREG{

    margin-top: 0%;

  }

 

  .secodnDivForm{
    margin-top: 5%;

  }


  .reFressCaptcha{

    height: 40px;
    width: 50px;
   
    
  }

    .loginHeaderDivO{
      margin: 0%;
      width: 100%;
     
     
      height: 100px;
    }



    .rainbowTextDiv{
      width: 65%;
    }


    .rainbowImageDiv{
      width: 100%;
      
    }



  .rainbowImage{
    display: none;
    
    
  }
 


}