.productImageShowShopProduct {
    height : 200px ; 
    width : 200px;
}
.css-1diafny-MuiSlider-root{
    color: #f39c12;
}


.searchBarAndPriceFilterDiv{
  margin-top : 50px ;
}

/* .multiRangeSliderDiv {
    /* padding-top: 10px; */
    /* border : 1px solid red;  */
/* } */
/* .searchBarDiv {
    /* border : 1px solid red;  */
/* }  */
.priceRangeSpan {
    margin-bottom : 5px;
}

/* .multiRangeSliderDiv {
    margin-top :100px;
} */

@media screen and (max-width: 479px) {
    .searchBarAndPriceFilterDiv{
        /* border : 1px solid rgb(83, 53, 219) ; */

        margin-bottom  : 50px ; 
    }
}