.submitButton
{
        background-color: white;
        color: #002fa7;
        margin-top: 2%;
        border: 2px solid #002fa7;
        padding: 5px 25px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 15px;
}

.contactHeader
{
        text-align: center;
        font-size: 30px;
        margin-top: -1%;
        margin-bottom: 2%;
        text-decoration: none;
        color:#002fa7;
}
.formInputFiled
{
        background-color: white;
        border: none;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding-left:1%;
        width: 100%;
        height: 55px;
}
.formInputFiledSeleted
{
        background-color: white;
        border: none;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding-left:1%;
        width: 100%;
        height: 55px;
}

.formInputFiledMessage{
        background-color: white;
        border: none;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding-left:1%;
        width: 100%;
        height: 155px;
}
.contactUSmainDiv{
        margin-top: 5%;
}


@media (max-width: 900px)
{
        .formInputFiled
        {
                background-color: white;
                border: none;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                padding-left: 2%;
                width: 100%;
                height: 55px;
                border-radius: 3%;
        }

        .contactHeader
{
        text-align: center;
        font-size: 30px;
        margin-top: 50px;
        margin-bottom: 2%;
        text-decoration: none;
        color:#002fa7;
}
}

@media (max-width: 480px) 
{
        .formInputFiled
        {
                background-color: white;
                border: none;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                padding-left: 2%;
                width: 100%;
                height: 40px;
                border-radius: 3%;
                margin-top: 3%;
        }

        .contactHeader
        {
        text-align: center;
        font-size: 30px;
        margin-top: 50px;
        margin-bottom: 2%;
        text-decoration: none;
        color:#002fa7;
        }

        .formInputFiledMessage{
               margin-top: 3%;
        }
}