.aboutUSMainDiv{
    margin-top: 8%;
}

.contectUSIcon{
    width: 15%;
    margin-bottom: 2%;

}
.contactUSText{
    padding: 0%;
    margin: 0%;
}
.contactUSImage{
    margin-top: 10%;
    width: 100%;
    height: 420px;
}


@media (max-width: 500px) 
{

    .aboutUSMainDiv{
        margin-top: 0%;
    }
    .contactUSImage{
        height: 100%;
    }

}