.okButtonMailSentModal {
    background-color: #F39C12;

    /* border-radius : 5px ; */
    color:white;
    padding : 0px 10px;
    border:1px solid #f39c12
}


.catchaCodeForgotCustomerPassword{
    position: absolute;
    /* padding-top: 5vh; */
    left: 12vw;
    margin-top : 15px;
    top: -07%; 
}

@media screen and (max-width: 480px) {



    .catchaCodeForgotCustomerPassword{
        position: absolute;
        padding-top: 5vh;
        left: 34vw;
        top: -45%;
        margin-top : 15px;
    }
 }