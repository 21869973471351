.login {
  background-color: #f2f2f2;
  position: relative;
  margin: 2vh auto;
  padding: 20px 20px 20px;
  width: 440px;
  height: fit-content;

  border-radius: 3px;
  box-shadow: 0 1px 14px #f2f2f2;
}


.LoginButtonNewButton {
  background-color: #F39C12;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 20%;
  padding-left: 20%;
  margin-top: 1.5%;
  /* margin-left: 20%; */
  outline: none;
  color: white;
  border: none;
}

.registrationLinkButton {
  background-color: #F39C12;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 7%;
  padding-left: 7%;
  outline: none;
  color: white;
  border: none;
  width: 100%;

  text-decoration: none;
}

.registrationLinkButton:hover {
  color: white;
}

.LoginButtonNewButton:hover {
  color: white;
}

.loginDivMobileSet {
  display: none;
}

.supportButton {
  background-color: #F39C12;
  width: 100%;
  padding: 1%;
  margin-top: 1.5%;
  outline: none;
  color: white;
  border: none;
}

.registrationDivMobileSet {
  margin-top: 4%;
  display: block;
}

.registrationDivMobileSetTwo {
  display: none;
  width: 100%;
}

.loginDivMobileSetTwo {
  display: block;
}



.passwordClass {

  width: 400px;

}

.forgotPass {

  font-size: 120%;
  color: blue;
}


.contactButton {

  width: 100%;
  background-color: darkcyan;
  border-radius: 20Px;
  margin-top: 0%;
  height: 30px;
}


.contactButton:focus {

  outline: none;

}



.login:before {
  content: '';
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  z-index: -1;

  border-radius: 4px;
}

.login h1 {
  margin: -20px -20px 21px;
  line-height: 40px;
  font-size: 15px;
  font-weight: bold;
  color: #555;
  text-align: center;
  text-shadow: 0 1px white;
  background: #f3f3f3;
  border-bottom: 1px solid #cfcfcf;
  border-radius: 3px 3px 0 0;
  background-image: -webkit-linear-gradient(top, whiteffd, #eef2f5);
  background-image: -moz-linear-gradient(top, whiteffd, #eef2f5);
  background-image: -o-linear-gradient(top, whiteffd, #eef2f5);
  background-image: linear-gradient(to bottom, whiteffd, #eef2f5);
  -webkit-box-shadow: 0 1px whitesmoke;
  box-shadow: 0 1px whitesmoke;
}

.login p {
  margin: 20px 0 0;
}

.login p:first-child {
  margin-top: 0;
}



.login p.remember_me {
  float: left;
  line-height: 31px;
}

.login p.remember_me label {
  font-size: 12px;
  color: #777;
  cursor: pointer;
}

.login p.remember_me input {
  position: relative;
  bottom: 1px;
  margin-right: 4px;
  vertical-align: middle;
}

.login p.submit {
  text-align: right;
}

.login-help {
  margin: 20px 0;
  font-size: 11px;
  color: white;
  text-align: center;
  text-shadow: 0 1px #2a85a1;
}

.login-help a {
  color: #cce7fa;
  text-decoration: none;
}

.login-help a:hover {
  text-decoration: underline;
}

:-moz-placeholder {
  color: #c9c9c9 !important;
  font-size: 13px;
}

::-webkit-input-placeholder {
  color: #ccc;
  font-size: 13px;
}

input {
  font-family: 'Lucida Grande', Tahoma, Verdana, sans-serif;
  font-size: 14px;
}


.captchaImageLogin {

  margin-left: 0%;
  height: 7vh;
  width: 40%;
}

.zz {
  position: absolute;
  /* padding-top: 5vh; */
  left: 11vw;
  top: -45%;

}

.LoginButton {

  float: right;

}

.registButton {

  float: left;
}


.lt-ie9 input[type=text],
.lt-ie9 input[type=password] {
  line-height: 34px;
}

@media only screen and (max-width:1200px) {
  .LoginButtonNewButton {
    margin-top: 15px;
    padding : 5px 35px;
  }

}

@media only screen and (max-width:600px) {

  .registrationLinkButton {
    width: 140%;
  }

  .login {
    width: 100%;
    height: auto;
    margin: 0%;
    /* padding: 0px 2px 0px 2px; */
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 8px;
    box-shadow: 0;

  }


  .body {
    width: 100%;

    height: auto;
  }

  .loginDivMobileSet {
    display: block;
  }

  .registrationDivMobileSet {
    display: none;
  }

  .registrationDivMobileSetTwo {
    display: block;
    width: 100%;
  }

  .loginDivMobileSetTwo {
    margin-top: 20px;
    background-color: #F39C12;
    display: none;
  }

  .titleLogo {

    width: 250px;
    height: 100px;

  }

  .secondLogo {


    width: 100px;
    height: 100px;
  }


  .secondaryLogo .titleLogo {
    line-height: 50px;
    max-width: 100%;
  }



  .captchaImageLogin {


    height: 50px;
    width: 100%;
    margin-bottom: 10%;


  }


  .catchaCodeLogin {
    position: absolute;
    padding-top: 5vh;
    left: 34vw;
    top: -45%;


  }

  .reFreshCaptcha {
    height: 20px;
    width: 2px;
    cursor: pointer;
  }

  .login input {
    width: 100%;
  }


  .LoginButton {

    padding-top: 10%;
    float: none;
  }


  .registButton {

    padding-top: 10%;
    float: none;
    width: 100%;
  }


  .LoginButtonNewButton {
    background-color: #F39C12;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-left: 0%;

  }

  .registrationLinkButton {
    background-color: #F39C12;
    padding-top: 2%;
    padding-bottom: 2%;

  }

}