.PasswordGuidLinesImageSize{
    height: 10px;
    width: 10px;
    padding-right: 1px;
}

.passwordDivLineComponent{
    padding-top: 1vw;
    text-align: left
}
.passwordDivLineComponent p{
    font-size: 70%;
    margin-bottom: 1px;
}
.mobileDivLineComponent{
    padding-top: 15vw;
    ;

}

.passwordGuidline{
    font-size: 90%;
}

.mobileGuidline{
    font-size: 150%;
}

@media only screen and (max-width:767px) {

}