.topSide{
  background-color:  #f6f5f5;
  height: 60px;

  
}

.LogoImage{

 width: 80%;
 height: 75%;
 margin-top: 3%;
}

.LogoImageInvoicePage{

  width: 100%;
  float: left;
}

.companyName{

    font-size: 110%;
    margin-top: 20%;
    color: white;

}

.notesAndTerm{

  float: left;
  margin-top: 1%;
}


.OrderButtionsInvoice{

    background-color: #F39C12;
    width: 100%;
    padding: 0%;
    margin-top: 0%;
    border: none;
    
   
}


.printItem{

  text-align: left;
}

.totalAmountTab{

  float: right;

  margin-right: 5%;
  margin-top: 5%;
}

.addressFromTo{

  float: left;
}

.paraGraphPadding{

  padding: 0%;
  margin: 0%;
  text-align: left;
  align-items: left;
  font-size: 80%;
}


.companyNameInvoice{

  float: left;
  margin-top: 3.5%;
  margin-left: 2%;
}

.topBar{

  background-color: #f6f5f5	 ;
  height: 60px;
}

.bellTab{

  font-size: 150%;
  color: red;
  margin-top: 18%;

}

.logout{

  height: 35px;
  width: 100px;
  float: right;
  margin-right: 20%;
  margin-top: 10px;
  background-color: #F2F2F2;
  box-shadow: 0px 2px #888888;
  border: none;
  border-radius: 20px;

}

.support{

  height: 35px;
  width: 100px;
  float: right;
  margin-right: 0%;
  margin-top: 10px;
  background-color: #F2F2F2;
  box-shadow: 0px 2px #888888;
  border: none;
  border-radius: 20px;
}


.changePassword{

  height: 35px;
  width: 200px;
  text-align: center;
  padding-top: 5px;
  margin-right: 20%;
  margin-top: 10px;
  background-color: #F2F2F2;
  box-shadow: 0px 2px #888888;
  border: none;
  border-radius: 20px;
}

.logout:focus{

  outline: none;
}

.support:focus{

  outline: none;
}


.sideBARColor{

  background-color:#7FB3D5 ;
  height: 100vh;
}




.linkTagCss{

  font-size: 130%;
  color: black;
}


.linkTagCss:hover{

  color: blue;
  text-decoration: underline;

}

.ImageLogo{

  height: 2%;
}

.navbarFloat{

  float: left;
  background-color: antiquewhite;
  width: 200%;
}


.checkCLos{

  background-color: aquamarine;
  width: 100%;
}

.menuStyle{

  margin-top: 100px;

}

.menuStyle:hover{

  height: 350px;
}
.menuStyle Link {
  text-decoration: none;
  color: white;
  font-size: 20px;
  padding: 40px;
  display:inline-block;
}

ul {
  display: inline;
  margin: 0;
  padding: 0;
}
ul li {
    display: inline-block;
    width: 100%;
    
}
ul li:hover {background: transparent;}
ul li:hover ul {display: block;}
ul li ul {
  
  width: 200px;
  display: none;
}
ul li ul li { 
  background: transparent; 
  display: block; 
  margin-left: 10px;
  margin-top: 8px;
}
ul li ul li a {display:block !important;} 
ul li ul li:hover {background: orange;}

.useLink:hover{

  color: orange;
}

.dashImage{

  height: 25px;
  width: 25px;
}


.secondMenu{

  margin-top: 20px;
}

.powerBy{

position: absolute;
bottom: 40%;
width: 100%;
height: 50px;
right: 0%;
background-color: white;
}

.powerBy p{

  margin-left: 5%;
  font-weight: bold;
}

.powrdImage{

  height: 12%;
  width: 50%;
}

.secondMenu:hover{
  background-color: orange;
}


.titleText{

text-align: center;
margin-top: 10px;
}


.allDriverTab{

margin-top: 40px;
background-color: #1B4F72;
}


.threeROw{

margin-top: 20px;
}


.tabDriver{

height: 40px;
width: 90px;
background-color: wheat;
}

.tabDriver p{

padding-top: 8px;


}

.noofdata{

background-color: #1B4F72;
}



.sidebar {
width: 110%;
margin: 0;
padding: 0;
margin-left: 2px;
margin-top: 5px;
background-color: transparent;

}


.sidebar a {
display: block;
color: black;
padding: 7px;
text-decoration: none;
}

.sidebar a.active {
background-color: orange;
color: white;
}

.activeClass{
background-color: orange;
width: 110%;

}


.secondLiDiv {
width: 200px;
margin: 0;
padding: 0;
background-color: transparent;
}

.secondLiDiv a {

color: black;
text-decoration: none;
}

.secondLiDiv a.active {
position: absolute;
background-color: orange;
width: 100%;
color: white;
}




.sidebar a:hover:not(.active) {
background-color: #555;
color: white;
}


.secondLiDiv a:hover:not(.active){
  background-color: orange;
  color: white;
  width: 110%;

}

.dashboardMain{

margin-top: 30px;

}

.secondWalaDas{

margin-left: 10px;

}


.copyRight{

background-color: white;
position: absolute;
color: 	#0f0f57;
font-size: 12px;
bottom: 0px;    


}

.orangeColor{

color: orange;
}


.secondLiBar{
margin-left: 7%;
padding-top: 10px;

}


@media screen and (max-width: 700px) {
.sidebar {
  width: 100%;
  height: auto;
  position: relative;
}
.sidebar a {float: left;}
div.content {margin-left: 0;}
}

@media only screen and (max-width:600px){ 



  .linkTagCss{

    font-size: 100%;
  }

  .companyNameInvoice{

    float: left;
    margin-top: 0%;
    margin-left: 2%;
  }

  .totalAmountTab{
    width: 100%;
    float: left;
    border: 2px solid orange;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 10%;
  }
  .topSide{
    margin-top: 5vh;
    display: none;
  }
  .topBar{
    display: none;
  }

  
}



