.toggleShowFormButton {
    /* border:  1px solid black ; 
    
    border-radius : 5px ; */

    /* width: 200px; */
    /* float: left; */
    margin-right: 20%;
    align-items: left; 
    color:white; 
    margin-top: 10px;
    background-color: #33a7d9;
    /* box-shadow: 0px 2px #888888; */
    border: none;
    padding  : 5px 10px ;
    /* border-radius: 20px; */
}
.modeSetButtonsDiv {
  display : flex ; 
  width  : 40%;
  /* border: 1px solid orange ;  */
  align-items : center; 
  justify-content : center; 
  flex-direction: row;
}
/* table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  } */
  .modesetButton {
    border : none;
    padding : 5px 10px ; 
    margin : 10px ; 
    color  : white ; 
    /* border-radius : 3px ;  */
    background-color : #33a7d9
  }
.SubmitButtonAddToProductSlider{
    background-color: #33a7d9;
    width: 30%;
    padding: 1%;
    outline: none;
    color: white;
    border: none;
}

.SubmitButtonAddToProductSlider:disabled{
    background-color: #aaacab;
}