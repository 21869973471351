.toggleShowFormButton {
    /* border:  1px solid black ; 
    
    border-radius : 5px ; */

    /* width: 200px; */
    /* float: left; */
    margin-right: 20%;
    align-items: left; 
    color:white; 
    margin-top: 10px;
    background-color: #33a7d9;
    /* box-shadow: 0px 2px #888888; */
    border: none;
    padding  : 5px 10px ;
    /* border-radius: 20px; */
}

.SubmitButtonAddToProductSlider{
    background-color: #33a7d9;
    width: 30%;
    padding: 1%;
    outline: none;
    color: white;
    border: none;
}

.SubmitButtonAddToProductSlider:disabled{
    background-color: #aaacab;
}