.imageInEdit{
    border: 1px solid orange;
    height : 200px ; 
    width : 200px 
}

.buttonsInSliderData {
    margin : 0px 10px ; 
    padding : 3px 7px ; 
    
}